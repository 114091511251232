import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic07 from '../assets/images/pic07.jpg'

const ConceptualOverview = props => (
  <Layout>
    <Helmet>
      <title>Conceptual Overview</title>
      <meta name="description" content="Conceptual Overview" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Conceptual Overview</h2>
          <p>
            Learn why this stuff actually matters
          </p>
        </header>

        <section id="content">
          <a href="#" className="image fit">
            <img src={pic07} alt="" />
          </a>
        <p className="align-left">
        Your reagent’s molecules are constantly colliding with one another, and each collision that occurs with a threshold energy causes those molecules to degrade and no longer function for your experiment.  Gradually, you have a less functioning product, until eventually the product no longer performs to a minimum specification.

          </p>
          <p className="align-left">
        Your reagent is always degrading, and its shelf-life is not a date at which it no longer works, but rather the date at which it no longer works well “enough”.  Enough is somewhat subjective but is typically when a performance test result yields just 90% of the result at the time of manufacture.
          </p>
          <p className="align-left">
        Given infinite time, we could run a real-time stability study for all reagents and observe their performance at their optimal storage temperature and assign shelf lives with very high confidence.  However, shelf lives can be long, and we often don’t have the luxury to wait for real-time data, so an accelerated study can be run in parallel.  An accelerated study is completed at elevated temperature and humidity to raise the frequency at which those molecular collisions occur with the threshold energy for degradation.  It’s the difference between waiting for your milk to sour while stored in the fridge compared to the same milk sitting on your back step in the summer sun.  This document provides you the tools to use the results from the elevated temperature to make predictions about the shelf life at the long-term storage temperature.
          </p>
        <div className="align-center">
        <div className="div-inline">
        <ul className="actions align-center">
          <li>
            <a href="/" className="button">
              Sample chart of first order reaction
            </a>
            </li>
        </ul>
        </div>
        </div>
        </section>
      </div>
    </div>
  </Layout>
)

export default ConceptualOverview
